import { FlowAPI, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  shareYourMobilePlaceClickOnButton,
  shareYourMobilePlaceError,
  sympAppLoaded,
} from '@wix/bi-logger-wixlabs-ugc/v2';
import { shareYourMobilePlaceDetails } from '@wix/bi-logger-wixlabs-users/v2';

import { data } from '../components/Presets/viewer.controller';
import { ButtonsBI, ErrorSendTypeBI } from '../types/types';
import { shareYourMobilePlaceDetailsParams } from '@wix/bi-logger-wixlabs-users/dist/types/types';

export const bi = (flowAPI: FlowAPI | PlatformControllerFlowAPI) => {
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();
  const { compId } =
    (flowAPI as PlatformControllerFlowAPI).controllerConfig ?? '';
  const { language } = flowAPI.translations.config;

  return {
    shareYourMobilePlaceClickOnButton: (buttonType: ButtonsBI) =>
      biLogger.report(
        shareYourMobilePlaceClickOnButton({
          button_description: buttonType,
          comp_id: compId,
          type: data.preferredApp,
          country_number: language,
        }),
      ),
    shareYourMobilePlaceError: (error_type: ErrorSendTypeBI) =>
      biLogger.report(
        shareYourMobilePlaceError({
          error_type,
          comp_id: compId,
          type: data.preferredApp,
        }),
      ),
    sympAppLoaded: () =>
      biLogger.report(
        sympAppLoaded({
          comp_id: compId,
          type: data.preferredApp,
        }),
      ),
    shareYourMobilePlaceDetails: (params: shareYourMobilePlaceDetailsParams) =>
      biLogger.report(shareYourMobilePlaceDetails(params)),
  };
};
